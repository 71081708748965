import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import RescueChain from './RescueChain.jsx'
import SawGokan from './SawGokan';
import SawInfo from './SawInfo';
import SawType from './SawType';
// import TekigoComponent from './tekigo/tekigo';
import Category1 from './Category1';
import Category2 from './Category2';
import CatInfoForm from './CatInfoForm';

function App() {
  return (
    // <div>
    //   <CatInfoForm />
    // </div>
    <Router>
    <div>
      {/* Headerコンポーネントを呼び出す */}
      <Header />

      {/* メインコンテンツ */}
      <main>
        <div className="row">
          {/* サイドバー */}
          <div id="leftside" className="col-lg-3 px-lg-4">
            <Sidebar />
          </div>

          {/* メインコンテンツ部分 */}
          <div className="col-12 col-lg-9 pe-lg-5" style={{ marginTop: '60px' }}>
            {/* Reactでこの部分にコンテンツを挿入 */}
            <div id="app">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/chain/:maker/:brand" element={<RescueChain />} />
                <Route path="/sawgokan" element={<SawGokan />} />
                <Route path="/sawinfo" element={<SawInfo />} />
                <Route path="/sawtype" element={<SawType />} />
                <Route path="/category/:BIG_ID" element={<Category1 />} />
                <Route path="/category2/:BIG_ID/:CHI_ID" element={<Category2 />} />
                {/* 他のページも同様に追加可能 */}
              </Routes>
              {/* ここにメインコンテンツやReact Routerでルーティングしてコンテンツを挿入 */}
              <div className="content">
                {/* @yield('content')に相当する部分 */}
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Footerコンポーネントを呼び出す */}
      <Footer />
    </div>
    </Router>
    
  );
}

export default App;
