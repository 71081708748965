import React, { useState } from 'react';

import shop_logo from './images/shop_logo.jpg';


const Header = () => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);

    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    return (
        <header className="header">
            <div className="row fixed_header">
                <nav className="navbar col-12 col-lg-9 d-flex justify-content-between mx-auto navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <a className="navbar-brand ps-3" href="/">
                            <img src={shop_logo} alt="サンキョウロゴ" className="shop_logo" />
                        </a>
                        <div className="only_pc">
                            <p>お見積り・お問合せはこちら　Tel.<span className="tel">0895-72-5511</span></p>
                            <a href="https://chainsaw.shop-pro.jp/?mode=cart_inn" className="cart d-block text-end">
                                <button className="cart_btn">カートを見る</button>
                            </a>
                        </div>
                        <button 
                            className="navbar-toggler" 
                            onClick={toggleNavbar} 
                            type="button" 
                            aria-expanded={isNavbarOpen} 
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
