import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SawGokan = () => {
  const [irOregonData, setIrOregonData] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null); // エラー状態を追加
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
      const fetchIrOregonData = async () => {
          try {
              const response = await axios.get(`${apiUrl}/saw-gokan`);
              console.log('API Response:', response.data);
              setIrOregonData(response.data);
              console.log(`${apiUrl}/saw-gokan`);
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };

      fetchIrOregonData();
  }, [apiUrl]);
    
    return (
      <div id="pcgokan">
      <h2 className="gokan_title mb-3 ps-3">ソーチェンの互換性</h2>
      <p className="pt-4">
        替刃(ソーチェン)メーカーは、ハスクバーナ、オレゴン、スチールの３社が主要なメーカーとなっています。<br />
        しかし、メーカーが違ってもソーチェンにはそれぞれ互換性がありますので、対応する物であればお使いいただくことができます。<br />
        下記の表より互換性のあるソーチェンを確認できます。<br />
        表内の横一列には互換性がありますので、例えばオレゴンの90PXとスチールの61PMMC3は交換可能となります。
      </p>
      <div className="table_wrap scrollbar">
        <table className="table gokan-table">
          <tbody>
            <tr>
              <th rowSpan="2">ピッチ</th>
              <th rowSpan="2">ゲージ</th>
              <th colSpan="4">オレゴン</th>
              <th colSpan="2">ハスクバーナ</th>
              <th colSpan="3">スチール</th>
              <th colSpan="2">スーパーチップ</th>
              <th rowSpan="2">マキタ</th>
              <th rowSpan="2">共立</th>
            </tr>
            <tr>
              <th>-</th>
              <th>-</th>
              <th>縦挽き</th>
              <th>竹切り</th>
              <th>通常</th>
              <th>X-CUT</th>
              <th>新チェーン</th>
              <th>旧チェーン</th>
              <th>PD</th>
              <th>新チェーン</th>
              <th>旧チェーン</th>
            </tr>

            {/* サンプルデータの行 */}
            {irOregonData.length > 0 && (
              <><tr>
                <td rowSpan="5" className="border_r border_l">3/8"</td>
                <td className="border_r">0.43"/1.1mm</td>
                <td className="bg link">
                  <a href="/category2/186700/8">
                    <img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[3][16]}`} alt="90PX" />
                    <div>90PX</div>
                  </a>
                </td>
                <td className="bg">-</td>
                <td className="bg">-</td>
                <td className="bg">-</td>
                <td className="bg">-</td>
                <td className="bg">-</td>
                <td className="bg">
                  <img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[3][17]}`} alt="新61PMMC3" />
                  <div>61PMMC3</div>
                </td>
                <td className="bg">
                  <img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[3][18]}`} alt="旧61PMMC3" />
                  <div>61PMMC3</div>
                </td>
                <td className="bg">-</td>
                <td className="bg" colSpan="2">-</td>
                <td className="bg">-</td>
                <td className="bg">-</td>
              </tr><tr>
                  <td rowSpan="2" className="border_r">0.50"/1.3mm</td>
                  <td className="link">
                    <a href="/category2/186700/5">
                      <img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[2][16]}`} alt="91px" />
                      <div>91PX</div>
                      </a>
                  </td>
                  <td className="link"><a href="/category2/186700/40"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[1][16]}`} alt="91VXL" /><div>91VXL</div></a></td>
                  <td>-</td>
                  <td className="link"><a href="/category2/186700/9">91F</a></td>
                  <td><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[1][20]}`} alt="H35" /><div>H35</div></td>
                  <td className="link"><a href="/category2/2739079/2"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[1][21]}`} alt="S93G" /><div>S93G</div></a></td>
                  <td><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[1][17]}`} alt="新63PM3" /><div>63PM3</div></td>
                  <td><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[1][18]}`} alt="旧63PM3" /><div>63PM3</div></td>
                  <td><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[1][19]}`} alt="63PD3" /><div>63PD3</div></td>
                  <td colSpan="2">-</td>
                  <td>-</td>
                  <td>-</td>
                </tr><tr>
                  <td className="bg link"><a href="/category2/186700/21"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[10][16]}`} alt="72DPX" /><div>72DPX</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg link"><a href="/category2/186700/36">72RD</a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg link"><a href="/category2/1346511/4"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[10][22]}`} alt="新K301" /><div>K301</div></a></td>
                  <td className="bg link"><a href="/category2/1346511/4"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[10][23]}`} alt="旧K301" /><div>K301</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                </tr><tr>
                  <td className="border_r">0.58"/1.5mm</td>
                  <td className="link"><a href="/category2/186700/22"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[11][16]}`} alt="73DPX" /><div>73DPX</div></a></td>
                  <td>-</td>
                  <td className="link"><a href="/category2/186700/37">73RD</a></td>
                  <td>-</td>
                  <td><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[11][20]}`} alt="H42" /><div>H42</div></td>
                  <td className="link"><a href="/category2/2739079/4"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[11][21]}`} alt="C85" /><div>C85</div></a></td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td className="link"><a href="/category2/1346511/5"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[11][22]}`} alt="新K381" /><div>K381</div></a></td>
                  <td className="link"><a href="/category2/1346511/5"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[11][23]}`} alt="旧K381" /><div>K381</div></a></td>
                  <td>-</td>
                  <td>-</td>
                </tr><tr>
                  <td className="border_r">0.63"/1.6mm</td>
                  <td className="bg link"><a href="/category2/186700/23"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[12][16]}`} alt="75DPX" /><div>75DPX</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg link"><a href="/category2/186700/38">75RD</a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                </tr><tr>
                  <td rowSpan="2" className="border_r border_l">1/4"</td>
                  <td className="border_r">0.43"/1.1mm</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[14][17]}`} alt="71PM3" /><div>71PM3</div></td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td><a href="/category/2739084"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[14][25]}`} alt="M11" /><div>M11</div></a></td>
                  <td><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[14][24]}`} alt="A4S" /><div>A4S</div></td>
                </tr><tr>
                  <td className="border_r">0.50"/1.3mm</td>
                  <td className="bg link"><a href="/category2/186700/3"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[16][16]}`} alt="25AP" /><div>25AP</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg link"><a href="/category2/186700/10">25F</a></td>
                  <td className="bg link"><a href="/category2/2740582/2"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[4][20]}`} alt="H00" /><div>H00</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                </tr><tr>
                  <td rowSpan="5" className="border_r border_l">.325"</td>
                  <td className="border_r">0.43"/1.1mm</td>
                  <td className="link"><a href="/category2/186700/41"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[5][16]}`} alt="80TXL" /><div>80TXL</div></a></td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td className="link"><a href="/category2/2739079/1"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[5][21]}`} alt="SP21G" /><div>SP21G</div></a></td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr><tr>
                  <td rowSpan="2" className="border_r">0.50"/1.3mm</td>
                  <td className="bg link"><a href="/category2/186700/7"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[6][16]}`} alt="95TXL" /><div>95TXL</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[6][20]}`} alt="H30" /><div>H30</div></td>
                  <td className="bg link"><a href="/category2/2739079/3"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[6][21]}`} alt="SP33G" /><div>SP33G</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg link"><a href="/category2/1346511/1"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[6][22]}`} alt="新K201" /><div>K201</div></a></td>
                  <td className="bg link"><a href="/category2/1346511/1"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[6][23]}`} alt="旧K201" /><div>K201</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                </tr><tr>
                  <td className="link"><a href="/category2/186700/1"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[7][16]}`} alt="20BPX" /><div>20BPX</div></a></td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td className="link"><a href="/category2/1346511/1"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[6][22]}`} alt="新K201" /><div>K201</div></a></td>
                  <td className="link"><a href="/category2/1346511/1"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[6][23]}`} alt="旧K201" /><div>K201</div></a></td>
                  <td>-</td>
                  <td>-</td>
                </tr><tr>
                  <td className="border_r">0.58"/1.5mm</td>
                  <td className="bg link"><a href="/category2/186700/2"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[8][16]}`} alt="21BPX" /><div>21BPX</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg link"><a href="/category2/2740582/1"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[8][20]}`} alt="H25" /><div>H25</div></a></td>
                  <td className="bg"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[8][21]}`} alt="SP35G" /><div>SP35G</div></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg link"><a href="/category2/1346511/3"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[8][22]}`} alt="新K281" /><div>K281</div></a></td>
                  <td className="bg link"><a href="/category2/1346511/3"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[8][23]}`} alt="旧K281" /><div>K281</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                </tr><tr>
                  <td className="border_r">0.63"/1.6mm</td>
                  <td className="link"><a href="/category2/186700/32"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[9][16]}`} alt="22BPX" /><div>22BPX</div></a></td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[9][17]}`} alt="新26MR" /><div>26MR</div></td>
                  <td><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[9][18]}`} alt="旧26MR" /><div>26MR</div></td>
                  <td>-</td>
                  <td className="link"><a href="/category2/1346511/2"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[9][22]}`} alt="新K231" /><div>K231</div></a></td>
                  <td className="link"><a href="/category2/1346511/2"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[9][23]}`} alt="旧K231" /><div>K231</div></a></td>
                  <td>-</td>
                  <td>-</td>
                </tr><tr>
                  <td className="border_r border_l">.404"</td>
                  <td className="border_r">0.63"/1.6mm</td>
                  <td className="bg"><a href="/category2/186700/33"><img src={`https://sawapi.sankyo.ws/api/svg?name=${irOregonData[13][16]}`} alt="59ACL" /><div>59ACL</div></a></td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                  <td className="bg">-</td>
                </tr></>
            )}

            {/* 以下、他の行も同様に描画 */}
          </tbody>
        </table>
      </div>
    </div>
    );
};

export default SawGokan;