import React, { useEffect, useState } from 'react';

import discount from './images/discount.jpg';
import delivery from './images/delivery.jpg';
import present from './images/present.jpg';
import sawchian_image from './images/sawchian_image1.jpg';


const Home = () => {

  return (
    <div>

      {/* トップ画像セクション */}
      <div className="row" style={{ backgroundColor: '#333' }}>
        <div className="col-12 text-center">
          <img src={sawchian_image} alt="トップ画像" className="top_image" />
        </div>
      </div>

      {/* ハスクバーナーXCUTとオレゴンソーチェンのセクション */}
      <div className="row" style={{ background: 'linear-gradient(180deg, rgba(51,51,51,1) 0%, rgba(91,96,97,1) 100%)' }}>
        {/* Xcut */}
        <div className="col-lg-6 col-12 py-5">
          <div className="px-lg-5 husqvarna">
            <h5 className="text-center text-white p-3" style={{ backgroundColor: 'midnightblue' }}>ハスクバーナーXCUT</h5>
            <div className="card-body">
              <p className="text-white p-lg-3">
                高い切断効率X-CUTチェンは、抜群の切れ味。<br />
                従来製品より速く、滑らかに切断することが可能です。<br />
                作業スピードが向上することにより、身体への負荷を減らし、燃費にも貢献します。<br />
                X-CUTチェンは工場であらかじめ伸ばしてあるので、新品での張り・調整時間と手間が低減でき、リテンションが必要になる前に長時間作業することができます。<br />
                優れた潤滑性ドライブリンクとタイストラップの独自のオイル潤滑方式により、オイルの潤滑性、チェンの耐久性が向上しました。
              </p>
            </div>
          </div>
        </div>
        {/* Xcut */}

        {/* Oregon */}
        <div className="col-lg-6 col-12 py-5">
          <div className="px-lg-5 oregon">
            <h5 className="text-center text-white p-3" style={{ backgroundColor: '#c8102e' }}>オレゴンソーチェン</h5>
            <div className="card-body">
              <p className="text-white p-lg-3">
                チェーンソー用ソーチェンの大手メーカーであるオレゴン。<br />
                ソーチェンの切れ味はさることながら、ソーチェンガードリンクの特許を持ち、安全性にもこだわりを持っています。<br />
                また、国内のチェーンソーのほとんどにオレゴン製ソーチェンが標準で装備されています。<br />
              </p>
            </div>
          </div>
        </div>
        {/* Oregon */}
      </div>

      {/* おすすめ商品セクション */}
      <div className="row py-5">
        <div className="col-12">
          <h5 className="pt-5 text-center fw-bold">おすすめ商品</h5>
          <ul>
            <li></li> {/* ここに商品リストを追加 */}
          </ul>
        </div>
      </div>


      {/* お知らせセクション */}
      <div className="row py-5">
        <div className="col-12">
          <h5 className="pt-5 text-center fw-bold">お知らせ</h5>
          <ul className="pt-4 news d-flex">
            <li>
              <img src={discount} alt="" />
              ソーチェーンを値下げしました
            </li>
            <li>
              <img src={delivery} alt="" />
              1万円以上のご注文で送料サービスです
            </li>
            <li>
              <img src={present} alt="" />
              ソーチェーン10本ご注文で1本サービスです
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;