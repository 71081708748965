import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Category2 = () => {
  const { BIG_ID, CHI_ID } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [inited, setInited] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const getCategory2Data = async () => {
      try {
        const params = {
          c1: BIG_ID,
          c2: CHI_ID || null
        };
        const response = await axios.get(`${apiUrl}/category2`, { params });
        if (response.data.length > 0) {
          const mappedData = response.data.map(value => ({
            ...value,
            link: `https://chainsaw.shop-pro.jp/?pid=${value.id}`
          }));
          setData(mappedData);
          setInited(true);
        }
      } catch (error) {
        alert('データの取得に失敗しました。');
      }
    };

    getCategory2Data();
  }, [BIG_ID, CHI_ID, apiUrl]);

  const goLink = () => {
    navigate('/');
  };

  return (
    <>
      <h2 className="my-5 text-center text-lg-start ms-2 fw-bold">商品一覧</h2>
      <div className="list" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
        {data.map((item) => (
          <ul key={item.link} className="mb-4 list_item list-unstyled">
            <li>
              <a href={item.link}>
                <div className="card" style={{ border: '2px solid #2757a3' }}>
                  <div className="card-body" style={{ textAlign: 'center' }}>
                    <img src={item.c_image_url} alt={item.c_shohinmei} className="pb-3 shohin-img" />
                    <p className="card-text text-center">{item.c_shohinmei}</p>
                    <p>{item.c_price}円(税込)</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        ))}
      </div>
    </>
  );
};

export default Category2;
