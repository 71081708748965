import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Notiflix from 'notiflix';

const TekigoComponent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [maker, setMaker] = useState('supertip');
  const [brand, setBrand] = useState('all');
  const [formList, setFormList] = useState({ brandList: [] });
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [subdata, setSubdata] = useState([]);
  const [pageArray, setPageArray] = useState([]);
  const [isPage, setIsPage] = useState(false);
  const [listLen, setLenList] = useState([]);
  const [listSaw, setSawList] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    brand: '',
    keyword: '',
    cminch: '',
    saw: '',
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const initMaker = params.maker || 'oregon';
    const initBrand = params.brand || 'all';

    setMaker(initMaker);
    setBrand(initBrand);

    // データ取得
    fetchData({ type: initMaker, page: initMaker === 'oregon' ? 0 : undefined });
    fetchBrandList();
}, [params.maker, params.brand]);  // brandは依存配列に含めない

// brandまたはmakerが変更されたときにタイトルを設定
useEffect(() => {
    if (!brand || !formList.brandList) return;

    const selectedBrand = formList.brandList.find((br) => br.e_name === brand);
    const jBrand = selectedBrand ? selectedBrand.j_name : '';

    let title = '';
    if (maker === 'oregon') {
      title = `${jBrand}オレゴンソーチェン適合表`;
    } else if (maker === 'xcut') {
      title = `${jBrand}ハスクバーナ（XCUT）ソーチェン適合表`;
    } else if (maker === 'supertip') {
      title = `${jBrand}レスキューチェーン(スーパーチップチェーン)適合表`;
    }
    document.title = title;
}, [brand, maker, formList.brandList]);
  

  const fetchBrandList = async () => {
    try {
      const response = await axios.get(`${apiUrl}/brand-list`);
      const brandList = [{ id: 0, e_name: 'all', j_name: 'すべてのメーカーから' }, ...response.data];
      setFormList((prev) => ({ ...prev, brandList }));
    } catch (error) {
      Notiflix.Report.failure('エラー', 'データの取得に失敗しました。', '閉じる');
    }
  };


  const fetchData = async () => {
    Notiflix.Loading.standard('データ取得中');
    try {
      console.log('Request Params:', {
        brand: values.brand || '',
        keyword: values.keyword || '',
        cminch: values.cminch || '',
        saw: values.saw || ''
      });
      const response = await axios.get(`${apiUrl}/sawmatch-data`, {
        params: {
          brand: values.brand || '',
          keyword: values.keyword || '',
          cminch: values.cminch || '',
          saw: values.saw || '',
        },
      });
      console.log('API Response:', response.data);
      Notiflix.Loading.remove();

      const { data } = response.data;
      setSubdata(data || []);
      setData(data || []);
      
      setLenList(
        [
          { value: '', name: '選択してください' }, // 空の初期値
          ...(response.data.listLen || []).map((ls) => ({
            value: `${ls.cm},${ls.inch}`,
            name: `${ls.inch}インチ(${ls.cm}cm)`,
          }))
        ]
      );

      setSawList(
        [
          { value: '', name: '選択してください' }, // 空の初期値
          ...(response.data.listSaw || []).map((ls) => ({
            value: `${ls.chain_type}`, // 'chain_type' をvalueにセット
            name: `${ls.chain_type}`, // 'chain_type' をnameにセット
          }))
        ]
      );
    } catch (error) {
      Notiflix.Loading.remove();
      Notiflix.Report.failure('エラー', 'データの取得に失敗しました。', '閉じる');
    }
  };
  

  const handleSearch = (values) => {
    setValues(values); // フォームの値を更新
  };

  // ここで、valuesが変更されるたびにfetchDataを実行する
  useEffect(() => {
    fetchData();
  }, [values]); // valuesが変更されたらfetchDataを実行

  const handleClear = (setFieldValue) => {
    setValues({
      brand: 'all',
      keyword: '',
      cminch: '',
      saw: ''
    });
    // FormikのsetFieldValueを使ってリセットする
  setFieldValue('brand', 'all');
  setFieldValue('keyword', '');
  setFieldValue('cminch', '');
  setFieldValue('saw', '');
    // fetchData();
  };

  const handleBrandChange = (e) => {
    const selectedBrand = e.target.value;

    // フォームの値を更新
    setValues((prevValues) => ({
      ...prevValues,
      brand: selectedBrand,  // ブランドをフォームの値にセット
    }));
    
    if (selectedBrand === 'all') {
      // 'all' が選択された場合の遷移処理（必要であれば）
      navigate(`/chain/${maker}/all`);
    } else {
      // 通常のブランドが選ばれた場合
      navigate(`/chain/${maker}/${selectedBrand}`);
    }
  };

  return (
    <>
      <h1 className='tekigo-title my-lg-5 ps-3'>{document.title}</h1>
      <Formik
        initialValues={{ brand: 'all', keyword: '', cminch: '', saw: '' }}
        validationSchema={Yup.object({
          brand: Yup.string().required(),
        })}
        onSubmit={handleSearch}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="row">
            <div className="col-lg-12 mt-2 text-center">
            メーカーを選択　
              <Field name="brand" as="select" style={{ width: "50%" }} className="py-1" onChange={handleBrandChange} value={values.j_name}>
                {formList.brandList.map((br) => (
                  <option key={br.id} value={br.e_name} className='text-center'>
                    {br.j_name}
                  </option>
                ))}
              </Field>
            </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-3">
                <Field name="keyword" placeholder="ここに型番を入力してください　例）GZ3950" className="py-1" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mt-3">バーサイズ</div>
                <div className="mt-1">
                  <Field name="cminch" as="select" className="py-1" style={{ width: "100%" }} >
                  
                    {listLen.map((len, index) => (
                      <option key={index} value={len.value}>
                      {len.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mt-3">
                  チェーンタイプ
                </div>
                <div className="mt-1">
                <Field name="saw" as="select" className="py-1" style={{ width: "100%" }} >
                
                    {listSaw.map((len, index) => (
                      <option key={index} value={len.value}>
                      {len.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            </div>
            <div className='text-center mt-3'>
              <button type="submit" className="btn common-btn">絞り込み</button>
              <button type="button" onClick={() => handleClear(setFieldValue)}className="btn common-btn ms-2">解除</button>
            </div>
          </Form>
        )}
      </Formik>
      {/* データ表示 */}

      <div className="row">
      <div className="col-12">
        <table className=" tekigo-table mt-3" style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>メーカー型式</td>
              <td>品番</td>
              <td className="text-center">サイズ</td>
              <td className="text-center">ピッチ,ゲージ<br />ドライブリンク</td>
              <td className="text-center">オレゴン品番</td>
              <td className="text-center">スーパーチップ品番</td>
              <td className="text-center">適合ガイドバー</td>
            </tr>
          </thead>
          <tbody>
            {data.map((d, idx) => (
                  <tr key={idx}>
                      <td rowSpan={d.length}>
                        <small>{d.k_brand}</small><br />
                        {d.k_model}
                      </td>
                    <td>{d.k_model2}</td>
                    <td className="text-center">{`${d.sm_bar_size_cm} (${d.sm_bar_size_in})`}</td>
                    <td className="text-center" nowrap="nowrap">
                      {d.PITCH} ({d.GAUGE_MM / 10}) {d.sm_dr_link}
                    </td>
                    <td className="text-center">
                      <a href={`https://chainsaw.shop-pro.jp/?pid=${d.oregonlink}`}>{d.oregon}</a>
                    </td>
                    <td className="text-center">
                      <a href={`https://chainsaw.shop-pro.jp/?pid=${d.superchiplink}`}>{d.superchip}</a>
                    </td>
                    <td className="text-center">
                    <ul className='bar_lists'>
                      {d.bar && Array.isArray(d.bar) && d.bar.length > 0 ? (
                        d.bar.map((b, index) => (
                          <li key={index}>
                          {b.colormes_id !== -1 ? (
                            <a href={`https://chainsaw.shop-pro.jp/?pid=${b.colormes_id}`} target="_blank" rel="noopener noreferrer">
                              {b.bm_kataban}
                            </a>
                          ) : (
                            <span>{b.bm_kataban}</span>
                          )}
                          </li>
                        ))
                      ) : (
                        <li></li>
                      )}
                    </ul>
                      
                      {/* {d.bar.map((b, j) => (
                        <p className="p-1 m-1" key={j}>
                          {b.colormes_id !== -1 ? (
                            <a href={`https://chainsaw.shop-pro.jp/?pid=${b.colormes_id}`}>{b.bm_kataban}</a>
                          ) : (
                            <span>{b.bm_kataban}</span>
                          )}
                        </p>
                      ))} */}
                    </td>
                  </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

      {/* ページネーション */}
      {/* {isPage && (
        <div>
          {pageArray.map((page) => (
            <button
              key={page.val}
              className={page.IsActive ? 'active' : ''}
              onClick={() => fetchData({ page: page.val })}
            >
              {page.name}
            </button>
          ))}
        </div>
      )} */}
    </>
  );
};

export default TekigoComponent;
