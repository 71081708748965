import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from "bootstrap"; 

import engraving_husqv from './images/engraving_husqv.jpg';
import engraving_oregon from './images/engraving_oregon.jpg';
import engraving_stihl from './images/engraving_stihl.jpg';
import check_mark from './images/check_mark.jpg';
import name_oregon from './images/name_oregon.jpg';
import chain_type_oregon from './images/chain_type_oregon.jpg';
import drive_link from './images/drive_link.jpg';
import type_oregon from './images/type_oregon.jpg';
import name_husqv from './images/name_husqv.jpg';
import chain_type_husqv from './images/chain_type_husqv.jpg';
import type_husqv from './images/type_husqv.jpg';
import name_stihl from './images/name_stihl.jpg';
import chain_type_stihl from './images/chain_type_stihl.jpg';
import next from './images/next.jpg';
import type_stihl from './images/type_stihl.jpg';



const SawType = () => {

  const scrollToAnchroingPosition = (id) => {
    const anchor = document.getElementById(id);
    if (anchor) {
      window.scrollTo({ top: anchor.offsetTop, behavior: 'smooth' });
    }
  };

  const OregonmodalRef = useRef(null);
  const OregonopenModal = () => {
    const modal = new Modal(OregonmodalRef.current);
    modal.show();
  };
  const OregoncloseModal = () => {
    const modal = Modal.getInstance(OregonmodalRef.current);
    modal.hide();
  };

  const HusqvmodalRef = useRef(null);
  const HusqvopenModal = () => {
    const modal = new Modal(HusqvmodalRef.current);
    modal.show();
  };
  const HusqvcloseModal = () => {
    const modal = Modal.getInstance(HusqvmodalRef.current);
    modal.hide();
  };

  const StihlmodalRef = useRef(null);
  const StihlopenModal = () => {
    const modal = new Modal(StihlmodalRef.current);
    modal.show();
  };
  const StihlcloseModal = () => {
    const modal = Modal.getInstance(StihlmodalRef.current);
    modal.hide();
  };

  return (
    <div>
    <h2 className="guide mb-5 ps-3">ソーチェンの選び方</h2>
    <p>交換したいソーチェンをお手元にご用意ください。</p>

    <section className="m-lg-5">
      <p className="step py-4 ps-3 fs-4">Step1.ソーチェンのメーカーをお選びください</p>
      <h5 className="mt-5 vertical_line">ソーチェンの見分け方</h5>
      <ul className="engraving">
        <li className="py-4">
          <img src={check_mark} alt="" className="check_mark" />
          <p>つなぎの部分に「OREGON」または「O」と記載があるものはオレゴン社製ソーチェンです。</p>
          <img src={engraving_oregon} alt="" />
          <button className="common_btn my-3 chain_btn" onClick={() => scrollToAnchroingPosition('oregon')}>
            オレゴンソーチェンへ
          </button>
        </li>
        <li className="py-4">
          <img src={check_mark} alt="" className="check_mark" />
          <p>つなぎの部分に「Husqv」または「H」と記載があるものはハスクバーナ社製ソーチェンです。</p>
          <img src={engraving_husqv} alt="" />
          <button className="common_btn my-3 chain_btn" onClick={() => scrollToAnchroingPosition('husqv')}>
            ハスクバーナソーチェンへ
          </button>
        </li>
        <li className="py-4">
          <img src={check_mark} alt="" className="check_mark" />
          <p>刃の部分に「STIHL」と記載があるものはスチール社製ソーチェンです。</p>
          <img src={engraving_stihl} alt="" />
          <button className="common_btn my-3 chain_btn" onClick={() => scrollToAnchroingPosition('stihl')}>
            スチールソーチェンへ
          </button>
        </li>
      </ul>
    </section>

    <section id="oregon" className="m-lg-5">
      <img src={name_oregon} alt="オレゴンソーチェン" className="chain" />
      <p className="step py-4 ps-3 fs-4">Step2.刻印された数字を確認してください</p>
      <img src={chain_type_oregon} alt="" />

      <button className="common_btn blade" onClick={OregonopenModal}>
        刃の種類を詳しく見る
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        ref={OregonmodalRef}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
              オレゴンソーチェン
              </h1>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={OregoncloseModal}
              ></button>
            </div>
            <div className="modal-body">
              <table className='sawtype-table'>
                <tbody>
                  <tr>
                    <th>番号</th>
                    <th>刃の種類</th>
                    <th>特徴</th>
                  </tr>
                  <tr>
                    <td rowspan="2">25</td>
                    <td>25AP</td>
                    <td>保守の容易な高性能チェーン(プロユーザー・セミプロユーザー向け)</td>
                  </tr>
                  <tr>
                    <td>25F</td>
                    <td>密集した繊維の切断に(旧称：竹切り)</td>
                  </tr>
                  <tr>
                    <td>90</td>
                    <td>90PX</td>
                    <td>切り幅が最も狭い軽量チェーン(90SGの改良版)</td>
                  </tr>
                  <tr>
                    <td rowspan="5">91</td>
                    <td>91VG</td>
                    <td>現在の91PXシリーズの旧型</td>
                  </tr>
                  <tr>
                    <td>91PX</td>
                    <td>チェンソー初心者からベテランまで幅広い用途に対応(91VGの改良版)</td>
                  </tr>
                  <tr>
                    <td>91VS</td>
                    <td>現在の91VXLシリーズの旧型</td>
                  </tr>
                  <tr>
                    <td>91VXL</td>
                    <td>刃を従来より33%長くすることで鋭い切れ味が持続(91VSの改良版)</td>
                  </tr>
                  <tr>
                    <td>91F</td>
                    <td>密集した繊維の切断に(旧称：竹切り)</td>
                  </tr>
                  <tr>
                    <td rowspan="2">95</td>
                    <td>95VPX</td>
                    <td>95TXLと互換性あり</td>
                  </tr>
                  <tr>
                    <td>95TXL</td>
                    <td>高い耐久性(95VPXの改良版)</td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>20BP(BPX)</td>
                    <td>プロユーザー向け。BPとBPXは互換性あり</td>
                  </tr>
                  <tr>
                    <td>21</td>
                    <td>21BP(BPX)</td>
                    <td>プロユーザー向け。BPとBPXは互換性あり</td>
                  </tr>
                  <tr>
                    <td>22</td>
                    <td>22BP(BPX)</td>
                    <td>プロユーザー向け。BPとBPXは互換性あり</td>
                  </tr>
                  <tr>
                    <td>72</td>
                    <td>72DP(DPX)</td>
                    <td>保守の容易さと高性能を同時に実現。DPとDPXは互換性あり</td>
                  </tr>
                  <tr>
                    <td>73</td>
                    <td>73DP(DPX)</td>
                    <td>保守の容易さと高性能を同時に実現。DPとDPXは互換性あり</td>
                  </tr>
                  <tr>
                    <td>75</td>
                    <td>75DP(DPX)</td>
                    <td>保守の容易さと高性能を同時に実現。DPとDPXは互換性あり</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <p className="step py-4 ps-3 fs-4">Step3.ドライブリンクの数を数えてください</p>
      <img src={drive_link} alt="ドライブリンク数" />
      <div className="next">
        <img src={next} alt="" />
      </div>
      <div className="finish">
        <img src={type_oregon} alt="品番" />
        <Link to="/sawgokan" className="d-inline-block compatibility">
          オレゴンソーチェンと互換性のあるソーチェンを知りたい方はこちら
        </Link>
      </div>
    </section>

    <section id="husqv" className="m-lg-5">
        <img src={name_husqv} alt="ハスクバーナソーチェン" className="chain"/>
        <p className="step py-4 ps-3 fs-4">Step2.刻印された数字を確認してください</p>
        <img src={chain_type_husqv} alt="" />

        <button className="common_btn blade" onClick={HusqvopenModal}>
        刃の種類を詳しく見る
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        ref={HusqvmodalRef}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
              ハスクバーナソーチェン
              </h1>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={HusqvcloseModal}
              ></button>
            </div>
            <div className="modal-body">
              <table className='sawtype-table'>
              <tbody>
                  <tr>
                    <th>番号</th>
                    <th>刃の種類</th>
                  </tr>
                  <tr>
                    <td>33</td>
                    <td>XCUT SP33G</td>
                  </tr>
                  <tr>
                    <td>35</td>
                    <td>XCUT S35G</td>
                  </tr>
                  <tr>
                    <td>85</td>
                    <td>XCUT C85</td>
                  </tr>
                  <tr>
                    <td>93</td>
                    <td>XCUT S93G</td>
                  </tr>
                  <tr>
                    <td>00</td>
                    <td>H00</td>
                  </tr>
                  <tr>
                    <td>25</td>
                    <td>H25</td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td>H30</td>
                  </tr>
                  <tr>
                    <td>35</td>
                    <td>H35</td>
                  </tr>
                  <tr>
                    <td>36</td>
                    <td>H36</td>
                  </tr>
                  <tr>
                    <td>37</td>
                    <td>H37</td>
                  </tr>
                  <tr>
                    <td>42</td>
                    <td>H42</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

        <p className="step py-4 ps-3 fs-4">Step3.ドライブリンクの数を数えてください</p>
        <img src={drive_link} alt="ドライブリンク数" />
        <div className="next">
          <img src={next} alt="" />
        </div>
        <div className="finish">
          <img src={type_husqv} alt="品番" />
          <Link to="/sawgokan" className="d-inline-block compatibility">
            ハスクバーナソーチェンと互換性のあるソーチェンを知りたい方はこちら
          </Link>
        </div>
      </section>

      {/* Stihl Section */}
      <section id="stihl" className="m-lg-5 mb-5">
        <img
          src={name_stihl}
          alt="スチールソーチェン"
          className="chain"
        />
        <p className="step py-4 ps-3 fs-4">Step2.刻印された数字を確認してください</p>
        <img src={chain_type_stihl} alt="" />

        <button className="common_btn blade" onClick={StihlopenModal}>
        刃の種類を詳しく見る
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        ref={StihlmodalRef}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
              スチールソーチェン
              </h1>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={StihlcloseModal}
              ></button>
            </div>
            <div className="modal-body">
              <table className='sawtype-table'>
              <tbody>
                  <tr>
                    <th>A</th>
                    <th>B</th>
                    <th>刃の種類</th>
                  </tr>
                  <tr>
                    <td>PM</td>
                    <td>1</td>
                    <td>61PMM3</td>
                  </tr>
                  <tr>
                    <td>P</td>
                    <td>3</td>
                    <td>63PM3</td>
                  </tr>
                  <tr>
                    <td>325</td>
                    <td>6</td>
                    <td>26RM</td>
                  </tr>
                  <tr>
                    <td>3/8</td>
                    <td>6</td>
                    <td>36RM</td>
                  </tr>
                  <tr>
                    <td>1/4</td>
                    <td>3</td>
                    <td>13RMS</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>1</td>
                    <td>61PMM3</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>3</td>
                    <td>63PM3</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>6</td>
                    <td>26RM</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>6</td>
                    <td>36RM</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>3</td>
                    <td>13RMS</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

        <p className="step py-4 ps-3 fs-4">Step3.ドライブリンクの数を数えてください</p>
        <img src={drive_link} alt="ドライブリンク数" />
        <div className="next">
          <img src={next} alt="" />
        </div>
        <div className="finish">
          <img src={type_stihl} alt="品番" />
          <Link to="/sawgokan" className="d-inline-block compatibility">
            スチールソーチェンと互換性のあるソーチェンを知りたい方はこちら
          </Link>
        </div>
      </section>

  </div>
  );
};

export default SawType;