import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Sidebar = ({ categories: initialCategories }) => {
    const [categories, setCategories] = useState(initialCategories || []);
    const [errorMessage, setErrorMessage] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${apiUrl}/categories`);
                console.log('API Response:', response.data);
                setCategories(response.data.categories); // APIからのデータを設定
                console.log(`${apiUrl}/categories`);
            } catch (error) {
                console.error('Fetch error:', error);
                setErrorMessage('データの取得に失敗しました。');
            }
        };

        fetchCategories();
    }, []);

    return (
        <div>
            {/* ソーチェン適合表 */}
            <div className="clearfix category category_m">
                ソーチェン適合表
            </div>
            <ul className="list-group">
                <li>
                    <a href="https://sankyo.ws/chain/brand" className="list-group-item list-group-item-action">
                        チェーンソー適合表
                    </a>
                </li>
                <li>
                  <Link to="/chain/supertip/all" className="list-group-item list-group-item-action">
                    レスキューチェーン適合表
                  </Link>
                </li>
            </ul>

            {/* ソーチェンについて */}
            <div className="clearfix category">
                ソーチェンについて
            </div>
            <ul className="list-group">
                <li>
                    <Link to="/sawinfo" className="list-group-item list-group-item-action">
                        ソーチェンの基礎知識
                    </Link>
                </li>
                <li>
                    <a href="/sawtype" className="list-group-item list-group-item-action">
                        ソーチェンの選び方
                    </a>
                </li>
                <li>
                    <Link to="/sawgokan" className="list-group-item list-group-item-action">
                        ソーチェンの互換性
                    </Link>
                </li>
            </ul>

            {/* カテゴリー */}
            <div className="row">
                <div className="col">
                    <div className="clearfix category">
                        カテゴリー
                    </div>
                    <ul className="list-group">
                        {/* categoriesが配列であることを確認 */}
                        {Array.isArray(categories) && categories.length > 0 ? (
                            categories.map((category) => (
                                <li key={category.BIG_ID}>
                                    <Link to={`/category/${category.BIG_ID}`} className="list-group-item list-group-item-action">
                                        {category.BIG_NAME}
                                    </Link>
                                </li>
                            ))
                        ) : (
                            <li className="list-group-item">カテゴリーがありません。</li>
                        )}
                    </ul>
                </div>
            </div>

            {/* エラーメッセージの表示 */}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        </div>
    );
};

export default Sidebar;
