import React from 'react';

import footer_logo from './images/footer_logo.jpg';


const Footer = () => {
    return (
        <div className="footer row">
            <div className="col-12 col-lg-9 d-flex justify-content-between mx-auto">
                <div className="footer_left">
                    <div>
                        <img src={footer_logo} alt="サンキョウロゴ" className="footer_logo mb-4" />
                    </div>
                    <p>〒798-4405<br />愛媛県南宇和郡愛南町満倉2702番地</p>
                    <p>TEL:0895-72-5511</p>
                    <p>FAX:0895-72-5512</p>
                    <p>営業時間 AM8:00～PM6:00</p>
                    <p>定休日 毎週日曜日</p>
                </div>
                <div className="footer_list">
                    <div>
                        <ul>
                            <li><a href="#">サンキョウについて</a></li>
                            <li><a href="https://chainsaw.shop-pro.jp/?mode=sk#payment">支払い方法について</a></li>
                            <li><a href="https://chainsaw.shop-pro.jp/?mode=sk#delivery">配送方法･送料について</a></li>
                            <li><a href="https://chainsaw.shop-pro.jp/?mode=sk">特定商取引法に基づく表記</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li><a href="https://chainsaw.shop-pro.jp/?mode=privacy">プライバシーポリシー</a></li>
                            <li><a href="https://chainsaw.shop-pro.jp/secure/?mode=inq&amp;shop_back_url=https%3A%2F%2Fchainsaw.shop-pro.jp%2F&amp;shop_id=PA01028590">お問い合わせ</a></li>
                            <li><a href="http://sankyo.ws/FAX.pdf" target="_blank" rel="noopener noreferrer">FAX注文用紙</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <p className="text-center copyright">&copy; 株式会社サンキョウ</p>
            </div>
        </div>
    );
};

export default Footer;
