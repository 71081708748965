import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Category1 = () => {
  const { BIG_ID } = useParams();
  const [cate1, setCate1] = useState(null);
  const [cate2Len, setCate2Len] = useState(0);
  const [data, setData] = useState([]);
  const [inited, setInited] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (BIG_ID && BIG_ID !== cate1) {
      setCate1(Number(BIG_ID));
      getCategoryData(BIG_ID);
    }
  }, [BIG_ID]);

  const getCategoryData = (BIG_ID) => {
    const params = { BIG_ID };
    axios.get(`${apiUrl}/category`, { params })
      .then(response => {
        const res = response.data;
        setData(res);
        if (res[0]?.cate2) {
          setCate2Len(res[0].cate2.length);
        }
        // cate2が1つだけのときは自動的に遷移
        if (res[0]?.cate2.length === 1) {
          navigate(`/category2/${res[0].cate2[0].BIG_ID}/${res[0].cate2[0].CHI_ID}`);
        }
        if (res.length > 0) {
          setInited(true);
        }
      })
      .catch(() => {
        alert("エラー: データの取得に失敗しました。");
      });
  };

  return (
    <>
      {cate2Len > 1 && (
        <>
          {!inited && <div className="col"></div>}
          {inited && (
            <div>
              <h2 className="my-5 fw-bold ms-2 text-center text-lg-start">{data[0]?.BIG_NAME}</h2>
              <div className="list" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
                {data[0]?.cate2 && data[0].cate2.map((d) => ( // cate2が存在するか確認
                  <ul key={`${d.BIG_ID}-${d.CHI_ID}`} className="mb-4 list_item list-unstyled">
                    <li>
                      <a href={`/category2/${d.BIG_ID}/${d.CHI_ID}`}>
                        <div className="card" style={{ border: '2px solid #2757a3' }}>
                          <div className="card-body" style={{ textAlign: 'center' }}>
                            <img src={d.CHI_image_url} className="pb-3" alt={d.CHI_NAME} />
                            <p className="card-text text-center">{d.CHI_NAME}</p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Category1;
